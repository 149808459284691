import storage from "@/utils/storage";
import { getListUserRole, listUserArea } from "@/apis/water-user.js";
import { getAreas } from "@/apis/water-area.js";
export default {
  namespace: true,
  state() {
    return {
      role: "",
      list: [],
    };
  },
  mutations: {
    changeList(state, val) {
      state.list = val;
    },
    changeRole(state, val) {
      state.role = val;
    },
  },
  actions: {
    getUserRole(context) {
      return new Promise((resolve) => {
        let userInfo = storage.get("loginUserInfo");
        getListUserRole(userInfo.id).then((res) => {
          if (res.succeeded) {
            context.commit("changeRole", res.data[0]);
            resolve(res.data[0]);
          }
        });
      });
    },
    getAreaList(context) {
      return new Promise((resolve) => {
        context.dispatch("getUserRole").then((res) => {
          if (res.roleId === 3) {
            let userInfo = storage.get("loginUserInfo");
            listUserArea({ id: userInfo.id }).then((res) => {
              if (res.succeeded) {
                context.commit("changeList", res.data);
                resolve(res.data);
              }
            });
          } else {
            getAreas().then((res) => {
              if (res.succeeded) {
                context.commit("changeList", res.data);
                resolve(res.data);
              }
            });
          }
        });
      });
    },
  },
};
