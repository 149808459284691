import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import area from "./modules/area";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,
  },
  mutations: {},
  modules: {
    user,
    area,
  },
});
