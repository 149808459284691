import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      name: "",
    },
    component: () => import("../views/page-logical"),
  },
  {
    path: "/bind",
    name: "bind",
    meta: {
      name: "绑定账户",
    },
    component: () => import("../views/page-bind"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      name: "账号登录",
    },
    component: () => import("../views/page-login"),
  },
  {
    path: "/list",
    name: "list",
    meta: {
      name: "水表列表",
      keepAlive: true,
    },
    component: () => import("../views/page-list"),
  },
  {
    path: "/my",
    name: "my",
    meta: {
      name: "个人",
    },
    component: () => import("../views/page-my"),
  },
  {
    path: "/message",
    name: "message",
    meta: {
      name: "消息",
    },
    component: () => import("../views/page-message"),
  },
  {
    path: "/payment-method",
    name: "payment-method",
    meta: {
      name: "水表列表",
    },
    component: () => import("../views/payment-method"),
  },
  {
    path: "/add-record",
    name: "add-record",
    meta: {
      name: "添加抄表记录",
    },
    component: () => import("../views/add-record"),
  },
  {
    path: "/edit-record",
    name: "edit-record",
    meta: {
      name: "修改抄表记录",
    },
    component: () => import("../views/edit-record"),
  },
  {
    path: "/used-water-history",
    name: "used-water-history",
    meta: {
      name: "用水记录",
    },
    component: () => import("../views/used-water-history"),
  },
  {
    path: "/record-detail",
    name: "record-detail",
    meta: {
      name: "抄表记录详情",
    },
    component: () => import("../views/record-detail"),
  },
  {
    path: "/on-site-payment",
    name: "on-site-payment",
    meta: {
      name: "缴费凭证编号",
    },
    component: () => import("../views/on-site-payment"),
  },
  {
    path: "/pay",
    name: "pay",
    meta: {
      name: "支付",
    },
    component: () => import("../views/pay"),
  },
  {
    path: "*",
    name: "index",
    meta: {
      name: "",
    },
    component: () => import("../views/page-logical"),
  },
];
const router = new VueRouter({
  routes,
});
// router.beforeEach((to, form, next) => {
//   window.document.title = to.meta.name || "水表收费系统";
//   next();
// });
export default router;
