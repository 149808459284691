import axios from "axios";
import router from "@/router";
import storage from "@/utils/storage";
import { Toast } from "vant";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: "https://api.xjjtgps.com/",
  timeout: 50000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let extra = config.headers.extra;
    if (extra && extra.showLoading) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
    }

    let userInfo = storage.get("loginUserInfo");

    if (userInfo && userInfo.accessToken) {
      config.headers["Authorization"] = "Bearer " + userInfo.accessToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    Toast.clear();
    //请求时在header里添加extra:{noError:true} 不显示报错信息
    let noError = false;
    let extra = response.config.headers.extra;
    if (extra) {
      noError = extra.noError;
    }
    const res = response.data;
    if (!res.succeeded && !noError) {
      Toast.fail(res.errors);
    }
    return res;
  },
  (res) => {
    Toast.clear();

    if (!res.response) {
      Toast.fail(res.message);
      return;
    }
    let status = res.response.status;
    if (status === 401) {
      storage.remove("loginUserInfo");
      storage.remove("listParams");
      Toast.fail("身份过期，请重新登录");
      router.push({
        path: "/",
      });
    } else {
      Toast.fail("服务器报错");
    }
    return Promise.reject(res);
  }
);

export default service;
