import storage from "@/utils/storage";
let info = storage.get("loginUserInfo") || {};
export default {
  namespace: true,
  state() {
    return {
      id: info.id || "",
    };
  },
};
