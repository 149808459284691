import BigNumber from "bignumber.js";

export function objFilterEmptyString(obj) {
  let newObj = {};
  for (let key in obj) {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function amend(num1 = 0, num2 = 100, symbol = "/") {
  if (symbol === "+") {
    return new BigNumber(num1).plus(num2).toNumber();
  }
  if (symbol === "-") {
    return new BigNumber(num1).minus(num2).toNumber();
  }
  if (symbol === "*") {
    return new BigNumber(num1).times(num2).toNumber();
  }
  if (symbol === "/") {
    return new BigNumber(num1).div(num2).toNumber();
  }

  // if (num1 === undefined) {
  //   return;
  // }
  // var str1 = num1.toString(),
  //   str2 = num2.toString(),
  //   result,
  //   str1Length,
  //   str2Length;

  // //解决整数没有小数点方法

  // //获取小数的位数
  // try {
  //   str1Length = str1.split(".")[1].length;
  // } catch (error) {
  //   str1Length = 0;
  // }
  // try {
  //   str2Length = str2.split(".")[1].length;
  // } catch (error) {
  //   str2Length = 0;
  // }

  // var step = Math.pow(10, Math.max(str1Length, str2Length));

  // let sum1 = parseInt(num1 * step);
  // let sum2 = parseInt(num2 * step);

  // switch (symbol) {
  //   case "+":
  //     result = (sum1 + sum2) / step;
  //     break;
  //   case "-":
  //     result = (sum1 - sum2) / step;
  //     break;
  //   case "*":
  //     result = (sum1 * sum2) / step / step;
  //     break;
  //   case "/":
  //     result = sum1 / sum2;
  //     break;
  //   default:
  //     break;
  // }
  // return result;
}
