import request from "@/utils/request.js";

/**
 * @description: 获取用户相关的地区
 * @param {*} query
 */
export function listUserArea(params) {
  return request({
    url: "/api/User/ListUserArea",
    method: "get",
    params,
  });
}

/**
 * @description: 获取指定用户所有权限
 */
export function getListUserRole(id) {
  return request({
    url: "/api/User/ListUserRole",
    method: "get",
    params: { id },
  });
}
/**
 * @description: 获取指定用户消息
 */
export function listMessage(params) {
  return request({
    url: "/api/User/ListMessages",
    method: "get",
    params,
  });
}
/**
 * @description: 用户消息已读操作
 */
export function readMessage(id) {
  return request({
    url: "/api/User/ReadMessage",
    method: "post",
    data: { ids: [id] },
  });
}
/**
 * @description: 用户消息已读操作
 */
export function deleteMessage(id) {
  return request({
    url: "/api/User/DeleteMessage",
    method: "post",
    data: { ids: [id] },
  });
}
