import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import Vant from "vant";
import "vant/lib/index.css";
import { amend } from "@/utils/func.js";
import { Toast } from "vant";
//过滤器
import "@/filters/index.js";
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$price = amend;
Vue.prototype.$toast = Toast;
// Vue.prototype.$store = store;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
