import request from "@/utils/request";

/**
 * @description: 获取所有区域名字及其对应编号
 * @param {*} query
 */
export function getAreas(query) {
  return request({
    url: "/api/WaterArea/GetAreas",
    method: "get",
    params: query,
  });
}

/**
 * @description: 新增区域
 * @param {*} data
 */
export function addArea(data) {
  return request({
    url: "/api/WaterArea/AddArea",
    method: "post",
    data,
  });
}
/**
 * @description: 删除区域（暂时不用）
 * @param {*} data
 */
export function removeArea(data) {
  return request({
    url: "/api/WaterArea/RemoveArea",
    method: "post",
    data,
  });
}
